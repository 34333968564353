import React from "react";
import textImage from "../../assets/middle-img.png";
import "./LeftContainer.css";

function LeftContainer() {
  return (
    <div className="left-container">
      {/* Image */}
      <img src={textImage} alt="100" className="left-image" />
    </div>
  );
}

export default LeftContainer;
