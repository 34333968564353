import { createSlice } from "@reduxjs/toolkit";

const navSlice = createSlice({
  name: "navSlice",
  initialState: {
    selectedNavItem: "Biographical Details",
    // selectedNavItem: "Obituary",   
  },
  reducers: {
    setNav: (state, action) => {
      return { ...state, selectedNavItem: action.payload };
    },
    clearNav: (state, action) => {
      return { ...state, selectedNavItem: "Biographical Details" };
    },
  },
});

export const { setNav, clearNav } = navSlice.actions;
export default navSlice.reducer;
