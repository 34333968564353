import "bootstrap/dist/css/bootstrap.css";
import RootLayout from "./components/root-layout/RootLayout";
import Store from "./store/Store";
import { Provider } from "react-redux";

function App() {
  return (
    <div className="App">
      <Provider store={Store}>
        <RootLayout />
      </Provider>
    </div>
  );
}

export default App;
