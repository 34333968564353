import React, { useEffect, useState } from "react";
import { setNav } from "../../store/reducers/NavReducer";
import Stepper from "../stepper/Stepper";
import { useDispatch } from "react-redux";
import { setForm } from "../../store/reducers/FormReduer";
import Loader from "../loader/loader";
import { generateObituaryData } from "../../utils/api/Api";
import { filterNonNullValues } from "../../utils/common-functions/CommonFunction";

function LeftFormContainer(props) {

  let {
    setGPTData, loading, setLoading,
    generatedData, draftPresence, setShowAllButtons, 
    setGeneratedData, regenerateBtn, setRegeneratebtn,
    submitDraft, setSubmitDraft, setDraftPresence
  } = props;
  
  const [opData, setOpData] = useState()
  const dispatch = useDispatch();  //To dispatch the values to store

  useEffect(() => {
    setGPTData(generatedData);
  }, [generatedData]);
  
  // to set the data coming from FAA
  useEffect(() => {
    window.addEventListener("message", (e) => {
      if (e?.data) {setOpData(e.data);}
    });
  }, []);

  const handleRegenerate = async (values) => {
    dispatch(setForm(values));   // setting values to reducer
    setLoading(true);
    //Based on the btn status it assigns the value to the initialValues of the form
    regenerateBtn ? values = { ...values, draftState: "re-generate" } : values = { ...values, draftState: "generate" }
    try {
      const apiPayload = filterNonNullValues(values);   /// to pass the payload to the api
      const obituaryData = await generateObituaryData(apiPayload);   /// to get the obituary draft
      setGeneratedData(obituaryData);
      setLoading(false);
        if (obituaryData) {
          dispatch(setNav({ activeTab: "Obituary" }));   ///Changing the header state
        }
        setRegeneratebtn(true);
        setShowAllButtons(true)
    } catch (error) {
        console.error("Error at getting obituary data", error);
        alert("Error", error.message);
        setLoading(false);
    }
  };

  // Returning the stepper component
  return (
    <div className="left-side-stepper">
      <Stepper
        handleSubmit={handleRegenerate}
        loading={loading}
        setLoading={setLoading}
        opData={opData}
        regenerateBtn={regenerateBtn}
        submitDraft={submitDraft}
        setSubmitDraft ={setSubmitDraft}
        generatedData = {generatedData}
        setDraftPresence={setDraftPresence}
        draftPresence={draftPresence}
        setShowAllButtons={setShowAllButtons}
      />
      {loading === true && (
        <>
          <Loader />
        </>
      )}
    </div>
  );
}

export default LeftFormContainer;
