// utils/api.js
import axios from "axios";

const api_url = "https://obituarygpt-draft-triggers-prod.azurewebsites.net/api";


export const validateEmail = async (email) => {
  try {
    const response = await axios.post(`${api_url}/emailvalidation-api`, { email });
    return response.data;
  } catch (error) {
    throw new Error("Error validating email");
  }
};

export const generateObituaryData = async (apiPayload) => {
  try {
    const response = await axios.post(
      `${api_url}/generatedraft-api`,
      apiPayload,
      { headers: { "Content-Type": "application/json" } }
    );
    return response;
  } catch (error) {
    throw new Error("Error generating obituary data");
  }
};
