import { createSlice } from "@reduxjs/toolkit";
import { initialValues } from "../../constants/ListOfFields";

const formSlice = createSlice({
  name: "formSlice",
  initialState: { initialValues },
  reducers: {
    setForm: (state, action) => {
      return action.payload;
    },
    clearForm: (state, action) => {
      return { initialState: initialValues };
    },
  },
});

export const { setForm, clearForm } = formSlice.actions;
export default formSlice.reducer;
