///Function to return the api payload

export const filterNonNullValues = (values) => {   
    const apiPayload = {};
    Object.keys(values).forEach(key => {
      if (values[key] !== null && values[key] !== "") {
        apiPayload[key] = values[key];
      }
    });
    return apiPayload;
  };