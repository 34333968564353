import React, { useState } from "react";
import "./RootLayout.css";
import Header from "../header/Header";
import LeftContainer from "../left-container/LeftContainer";
import RightContainer from "../right-container/RightContainer";
import LeftFormContainer from "../left-form-container/LeftFormContainer";
import ObituaryContainer from "../obituary/ObituaryContainer";
import { useSelector } from "react-redux";

function RootLayout() {
  const [activeSection, setActiveSection] = useState("Biographical Details");
  const activeTab = useSelector((state) => state.nav.selectedNavItem);
  // const activeTab = "Obituary"
  const [gptData, setGPTData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [generatedData, setGeneratedData] = useState(gptData);
  const [regenerateBtn, setRegeneratebtn] = useState(false)
  const [isFieldsUpdated, setFieldsUpdated] = useState(false);
  const [submitDraft, setSubmitDraft] = useState(false)
  const [draftPresence, setDraftPresence] = useState(false)
  const [showAllButtons, setShowAllButtons] = useState(false)

  return (
    <div className="root-layout">
      {/* Header */}
      <div>
        <Header
          activeSection={activeSection}
          setActiveSection={setActiveSection}
          isFieldsUpdated={isFieldsUpdated}
          regenerateBtn={regenerateBtn}
          setShowAllButtons={setShowAllButtons}
          showAllButtons={showAllButtons}
        />
      </div>

      {activeTab === "Biographical Details" ? (
        // Home page body is here
        <div className="homepage-body">
          <div className="left-container">
            <LeftContainer />
          </div>
          <React.Fragment>
            <RightContainer
              activeSection={activeSection}
              setActiveSection={setActiveSection}
              setGPTData={setGPTData}
              loading={loading}
              setLoading={setLoading}
              setGeneratedData={setGeneratedData}
              setFieldsUpdated={setFieldsUpdated}
              isFieldsUpdated={isFieldsUpdated}
              setRegeneratebtn={setRegeneratebtn}
            />
          </React.Fragment>
        </div>
      ) : (
        // after generation of text
        <div className="obituary-body">
          <div className="left-form-container">
            <LeftFormContainer
              setGPTData={setGPTData}
              generatedData={generatedData}
              setGeneratedData={setGeneratedData}
              loading={loading}
              setLoading={setLoading}
              regenerateBtn={regenerateBtn}
              setRegeneratebtn={setRegeneratebtn}
              submitDraft={submitDraft}
              setSubmitDraft={setSubmitDraft}
              draftPresence={draftPresence}
              setDraftPresence={setDraftPresence}
              setShowAllButtons={setShowAllButtons}
            />
          </div>
          <div className="right-text-container">
            <ObituaryContainer
              gptData={gptData}
              setSubmitDraft={setSubmitDraft}
              draftPresence={draftPresence}
              setDraftPresence={setDraftPresence}
              showAllButtons={showAllButtons}
              setShowAllButtons={setShowAllButtons}
              setGeneratedData={setGeneratedData}
              generatedData={generatedData}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default RootLayout;
