import React, { useRef, useState } from "react";
import Stepper from "../stepper/Stepper";
import { FormikProvider } from "formik";
import "./RightContainer.css";
import { useDispatch } from "react-redux";
import { setNav } from "../../store/reducers/NavReducer";
import { initialValues } from "../../constants/ListOfFields";
import { setForm } from "../../store/reducers/FormReduer";
import Loader from "../loader/loader";
import { validateEmail, generateObituaryData } from "../../utils/api/Api";
import { filterNonNullValues } from "../../utils/common-functions/CommonFunction";

function RightContainer(props) {
  let {
    activeSection, setActiveSection, setGPTData, loading, setLoading, 
    setGeneratedData, setFieldsUpdated, isFieldsUpdated, setRegeneratebtn
  } = props;

  const [isVerified, setIsVerified] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const isLoadingRef = useRef(false); // Initialize the ref for isLoading
  const dispatch = useDispatch();

  const activeObj = { activeTab: "Obituary" };
  const isDisplayedInIframe = window.self !== window.top
  {isDisplayedInIframe && dispatch(setNav(activeObj))}

  const handleSubmitForm = async (values) => {   //function to submit the form fields for the first time
    setLoading(true);
    dispatch(setForm(values));  // dispatching the form fields
    try {
      const isEmailValid = await validateEmail(values?.email);  //verifying the email
      if (isEmailValid) {
        setRegeneratebtn(true)
        setShowCaptcha(false);
        const apiPayload = filterNonNullValues(values);
        try{
          const obituaryData = await generateObituaryData(apiPayload);  // getting the obituary data
          setGPTData(obituaryData);
          setGeneratedData(obituaryData);
          if (obituaryData) {
            dispatch(setNav({ activeTab: "Obituary" }));
         }
        }catch(error){
          console.error("Error at generating obituary")   //handling the errors
        }
      } else {
        setErrorMessage("Email validation failed");
      }
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setLoading(false);   ///Setting of the loading t
    }
  };

  return (
    <div className="right-container">
      <FormikProvider value={initialValues}>
        <Stepper
          handleSubmit={handleSubmitForm}
          isVerified={isVerified}
          setIsVerified={setIsVerified}
          showCaptcha={showCaptcha}
          setShowCaptcha={setShowCaptcha}
          activeSection={activeSection}
          setActiveSection={setActiveSection}
          errorMessage={errorMessage}
          setLoading={setLoading}
          setErrorMessage={setErrorMessage}
          setFieldsUpdated={setFieldsUpdated}
          isFieldsUpdated={isFieldsUpdated}
        />
      </FormikProvider>
      {(isLoadingRef.current === true || loading) && (
        <>
          <Loader />
        </>
      )}
    </div>
  );
}

export default RightContainer;
