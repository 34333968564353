import React, { useEffect, useRef, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Col, Row } from "react-bootstrap";
import "./Stepper.css";
import Reaptcha from "reaptcha";
import dayjs from "dayjs";
import { ReactComponent as SVG } from "../../assets/in-new-tab.svg";
import { userTonesOptions, personalTraitsOptions, lengthOptions, 
  validationSchema, initialValues, hobbieOptions, steps } from "../../constants/ListOfFields";
import { Box, Stepper, Step, StepLabel, Tooltip, Zoom } from "@mui/material";
import { useSelector } from "react-redux";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export default function HorizontalNonLinearStepper(props) {
  // Extracing the props
  let {
    handleSubmit,
    isVerified,
    setIsVerified,
    showCaptcha,
    setShowCaptcha,
    activeSection,
    errorMessage,
    setLoading,
    setErrorMessage, setDraftPresence, draftPresence, setFieldsUpdated,
    opData, regenerateBtn, submitDraft, setSubmitDraft, generatedData
  } = props;

  // state for the active steps
  const [activeStep, setActiveStep] = useState(0);

  //active tab from the state
  const activeTab = useSelector((state) => state.nav.selectedNavItem.activeTab);
  const formData = useSelector((state) => state.form);

  const maxDate = new Date().toISOString().split("T")[0];

  // State to apply dynamicStyles
  const [selectedLength, setSelectedLength] = useState();
  const [selectedWritingTone, setSelectedWritingTone] = useState();
  const [wrtingToneMessage, setWritingToneMessage] = useState("");
  const [selectedHobbies, setSelectedHobbies] = useState([]);
  const [selectedTraits, setSelectedTraits] = useState([]);
  const isLastStep = activeStep === 3;
  const captchaRef = useRef();
  const siteKey = "6LfZczwpAAAAAJI3I5EJ7hR4rVlnmawjd-5t-Q0L";

  // Function to verify the captcha
  const handleVerify = () => {
    captchaRef.current.getResponse().then(async (res) => {
      setIsVerified(true);
    });
  };

  let newFormData;
  useEffect(()=>{
    newFormData = formData
  }, [formData])

  const saveDraft = (data)=>{
    let generatedText;
    if(generatedData?.data != null){
      generatedText = generatedData?.data
    }else if(generatedData != null){
      generatedText = generatedData
    }
    const dataToParent = {...data, generatedText }
    if(window.parent){
      window.parent.postMessage({action : 'createDraft', dataToParent}, "*")
      setSubmitDraft(false)
    }
  }

  const ObituaryValues = { ...newFormData?.initialValues, ...opData };

  // Function to navigate back
  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
    activeTab !== "Obituary" && setShowCaptcha(false);
  };

  useEffect(() => {
    if (activeTab === "Obituary") {
      if (formData?.personalityTraits || opData?.personalityTraits) {
        setSelectedTraits(formData?.personalityTraits?.split(", ") || opData?.personalityTraits?.split(", "));
      }
      if (formData?.hobbies || opData?.hobbies) {
        setSelectedHobbies(formData?.hobbies?.split(", ") || opData?.hobbies?.split(", "));
      }
      if (formData?.writingTone || opData?.writingTone) {
        setSelectedWritingTone(formData?.writingTone || opData?.writingTone);
      }
      if (formData?.length || opData?.length) {
        setSelectedLength(formData?.length || opData?.length);
      }
    }
  }, [formData, opData]);

  // all about handling the personality traits
  const handleOnChangeTypes = (selectedValue, selectedTypeData) => {
    let selectedPersonalityData;
    if (selectedTypeData?.includes(selectedValue?.label)) {
      selectedPersonalityData = selectedTypeData.filter(
        (trait) => trait !== selectedValue.label
      );
    } else if (selectedTypeData?.length < 5) {
      selectedPersonalityData = [...selectedTypeData, selectedValue?.label];
    }
    return selectedPersonalityData || selectedTypeData || [];
  };

  

  useEffect(() => {
    if (activeTab === "Obituary") {
      setActiveStep(3);
    }
  }, []);

  const isDisplayedInIframe = window.self !== window.top
  useEffect(()=>{
    {isDisplayedInIframe && setActiveStep(0)}
  },[])


  return (
    <Box
      sx={{
        width: "80%",
        margin: "auto",
        marginTop: "1.5rem",
      }}
      className={`${
        activeSection === "Biographical Details" ? "box" : "obituary-box"
      }`}
    >
      <Formik
        initialValues={activeTab === "Obituary" ? isDisplayedInIframe ? ObituaryValues : formData : initialValues}
        enableReinitialize
        validationSchema={() => {
          return showCaptcha !== true
            ? validationSchema[activeStep]
            : validationSchema[4];
        }}
        onSubmit={(values, { setSubmitting, setTouched }) => {
          if (isLastStep) {
            if (activeTab === "Obituary") {
              setLoading(true);
              handleSubmit(values);
            } else {
              setTouched({});
              setShowCaptcha(true);
            }
          } else {
            setSubmitting(false);
            setTouched({});
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
        }}
      >
        {({ dirty, values, touched, setFieldValue }) => (
          <Form>
            {submitDraft && saveDraft(values)}
            {/* Stepper component */}
            <Stepper
              alternativeLabel
              activeStep={activeStep}
              className="stepper"
              sx={{ paddingBottom: "1.2rem", maxWidth: "100%" }}
            >
              {steps.map((label, index) => (
                <Step key={label} style={{ maxWidth: "100%" }}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            {/* Rendering the forms based on the active step */}
            <div
              className={`${
                activeSection === "Biographical Details"
                  ? "outlet"
                  : "obituary-outlet"
              }`}
            >
              {activeStep === 0 && (
                <React.Fragment>
                  <Row>
                    {/* First Name */}
                    <Col sm={4}>
                      <div
                        style={{ paddingRight: "0.9rem" }}
                        className="form-group"
                      >
                        <label className="form-label" htmlFor="firstName">
                          First Name <span className="text-danger">*</span>
                        </label>
                        <Field
                          type="text"
                          id="firstName"
                          name="firstName"
                          className="form-control placeholder-text"
                          placeholder="David"
                        />
                        <ErrorMessage
                          name="firstName"
                          component="span"
                          className=" error text-danger"
                        />
                      </div>
                    </Col>

                    {/* Middle Name */}
                    <Col sm={4}>
                      <div style={{ paddingRight: "0.9rem" }}>
                        <label htmlFor="firstName" className="form-label">
                          Middle Name
                        </label>
                        <Field
                          type="text"
                          id="middleName"
                          name="middleName"
                          className="form-control placeholder-text"
                          placeholder="Justin"
                        />
                        <ErrorMessage
                          name="middleName"
                          component="span"
                          className="error text-danger"
                        />
                      </div>
                    </Col>

                    {/* Last Name */}
                    <Col sm={4}>
                      <div>
                        <label htmlFor="lastName" className="form-label">
                          Last Name
                        </label>
                        <Field
                          type="text"
                          id="lastName"
                          name="lastName"
                          className="form-control placeholder-text"
                          placeholder="Smith"
                        />
                        <ErrorMessage
                          name="lastName"
                          component="span"
                          className="error text-danger"
                        />
                      </div>
                    </Col>
                  </Row>

                  {/* Nick Name and Gender  */}

                  <Row className="pt-4">
                    {/* Nick Name */}
                    <Col sm={6}>
                      <div className="form-group">
                        <label className="form-label" htmlFor="nickName">
                          Nick Name
                        </label>
                        <Field
                          type="text"
                          id="nickName"
                          name="nickName"
                          placeholder="Davie"
                          className="form-control placeholder-text"
                        />
                        <ErrorMessage
                          name="nickName"
                          component="span"
                          className="text-danger"
                        />
                      </div>
                    </Col>

                    <Col sm={6}>
                      {/* Gender */}
                      <div className="form-group">
                        <label className="form-label" htmlFor="nickName">
                          Gender
                          <span className="text-danger"> *</span>
                        </label>
                        <Field
                          as="select"
                          id="gender"
                          name="gender"
                          className="form-select placeholder-text"
                        >
                          <option value="">Select</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </Field>
                        
                        <ErrorMessage
                          name="gender"
                          component="span"
                          className="error text-danger"
                        />
                      </div>
                    </Col>
                  </Row>

                  {/* Date of Birth and Death */}
                  <Row className="pt-4">
                    <Col sm={6}>
                      {/* Date */}
                      <div className="form-group mb-2">
                        <label className="form-label">Date of Birth </label>
                        <Field
                          type="date"
                          name="dateOfBirth"
                          max={maxDate}
                          className={`form-control placeholder-text ${
                            !values?.dateOfBirth ? "date-input" : ""
                          }`}
                        />
                        <ErrorMessage
                          name="dateOfBirth"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </Col>
                    <Col sm={6}>
                      <div className="form-group mb-2">
                        <label className="form-label">Date of Death </label>
                        <Field
                          type="date"
                          name="dateOfDeath"
                          max={maxDate}
                          className={`form-control placeholder-text ${
                            !values?.dateOfDeath ? "date-input" : ""
                          }`}
                        />
                        <ErrorMessage
                          name="dateOfDeath"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </Col>
                  </Row>

                  {/* Birth and place lived */}
                  <Row className="pt-4">
                    <Col sm={6}>
                      <div className="form-group mb-2">
                        <label className="form-label">Birth Place </label>
                        <Field
                          type="text"
                          name="birthplace"
                          placeholder="Austin, Texas"
                          className="form-control placeholder-text"
                        />
                        <ErrorMessage
                          name="birthplace"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </Col>
                    <Col sm={6}>
                      <div className="form-group mb-2">
                        <label className="form-label">Place Lived </label>
                        <Field
                          type="text"
                          name="placeLived"
                          placeholder="Colma, California."
                          className="form-control placeholder-text"
                        />
                        <ErrorMessage
                          name="placeLived"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </Col>
                  </Row>

                  {/* Death Cause and Location  */}
                  <Row className="pt-4">
                    <Col>
                      <div className="form-group mb-2">
                        <label className="form-label">Cause of Death</label>
                        <Field
                          name="deathCause"
                          type="text"
                          className="form-control placeholder-text"
                          placeholder="Natural Causes"
                        />
                        <ErrorMessage
                          name="deathCause"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </Col>
                  </Row>

                  {/* Educational Qualifications */}
                  <Row className="pt-4">
                    <Col>
                      <div className="form-group mb-2">
                        <label className="form-label">Education </label>
                        <Field
                          name="educationalQualification"
                          placeholder="Master's in Business Administration"
                          type="text"
                          className="form-control placeholder-text"
                        />
                        <ErrorMessage
                          name="educationalQualification"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </Col>
                  </Row>
                  {/* family members */}
                  <Row className="pt-3">
                    <Col>
                      <div className="mb-2">
                        <label className="form-label">Family Members</label>
                        <Field
                          type="text"
                          as="textarea"
                          id="familyMembers"
                          name="familyMembers"
                          className="form-control placeholder-text family-members"
                          placeholder="Spouse: Emily, Children: Sarah, James"
                        />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
              {activeStep === 1 && (
                <React.Fragment>
                  {/* Significant life moments */}
                  <Row>
                    <Col>
                      <div
                        className="significant-row"
                        style={{ marginTop: "1rem" }}
                      >
                        <label className="form-label">
                          Significant Life Moments
                        </label>
                        <Field
                          type="text"
                          as="textarea"
                          id="significantLifeMoments"
                          name="significantLifeMoments"
                          className="form-control placeholder-text"
                          style={{ minHeight: "100px" }}
                          placeholder="Graduated with Honors, Traveled Europe"
                        />
                      </div>
                    </Col>
                  </Row>

                  {/* Memories */}
                  <Row>
                    <Col>
                      <div
                        className="significant-row"
                        style={{ marginTop: "1rem" }}
                      >
                        <label className="form-label">Memories</label>
                        <Field
                          type="text"
                          as="textarea"
                          id="memories"
                          name="memories"
                          className="form-control placeholder-text"
                          style={{ minHeight: "100px" }}
                          placeholder="Family Barbecues, Ski Trips"
                        />
                      </div>
                    </Col>
                  </Row>

                  {/* Achievements */}
                  <Row>
                    <Col>
                      <div
                        className="significant-row"
                        style={{ marginTop: "1rem" }}
                      >
                        <label className="form-label">Achievements</label>
                        <Field
                          type="text"
                          as="textarea"
                          id="achievements"
                          name="achievements"
                          className="form-control placeholder-text"
                          style={{ minHeight: "100px" }}
                          placeholder="Employee of the Year, Published Author"
                        />
                      </div>
                    </Col>
                  </Row>

                  {/* Personality Traits */}
                  <Row>
                    <Col>
                      <div
                        className="significant-row"
                        style={{ marginTop: "1rem" }}
                      >
                        <label className="form-label">
                          Personality Traits{" "}
                        </label>
                        {/* {selectedTraits?.length >= 5 && ( */}
                        <span
                          className="fw-medium"
                          style={{
                            color: "#198754",
                            marginLeft: "15px",
                            fontSize: "12px",
                          }}
                        >
                          * Select Top 5
                        </span>
                        {/* )} */}
                        <div className="personalityTraitsContainer p-2">
                          {personalTraitsOptions.map((personality) => (
                            <div
                              className={`btn btn-sm btn-personality p-2 m-1 ${
                                selectedTraits.includes(personality.label)
                                  ? "selected-trait"
                                  : "not-selected-trait"
                              }`}
                              key={personality.value}
                              onClick={() => {
                                const resultPresonality = handleOnChangeTypes(
                                  personality,
                                  selectedTraits
                                );
                                setSelectedTraits(resultPresonality);
                                setFieldValue(
                                  "personalityTraits",
                                  resultPresonality.join(", ")
                                );
                              }}
                            >
                              {personality.label}
                            </div>
                          ))}
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {/* Hobbies */}
                  <Row>
                    <Col>
                      <div
                        className="significant-row"
                        style={{ marginTop: "1rem" }}
                      >
                        <label className="form-label">Hobbies </label>
                        {/* {selectedHobbies?.length >= 5 && ( */}
                        <span
                          className="fw-medium"
                          style={{
                            color: "#198754",
                            marginLeft: "15px",
                            fontSize: "12px",
                          }}
                        >
                          * Select Top 5
                        </span>
                        {/* // )} */}
                        <div className="hobbiesContainer p-2">
                          {hobbieOptions.map((hobbies) => (
                            <div
                              className={`btn btn-sm btn-personality p-2 m-1 ${
                                selectedHobbies.includes(hobbies.label)
                                  ? "selected-hobby"
                                  : "not-selected-hobby"
                              }`}
                              key={hobbies.value}
                              onClick={() => {
                                const resultHobiiesData = handleOnChangeTypes(
                                  hobbies,
                                  selectedHobbies
                                );
                                setSelectedHobbies(resultHobiiesData);
                                setFieldValue(
                                  "hobbies",
                                  resultHobiiesData.join(", ")
                                );
                              }}
                            >
                              {hobbies.label}
                            </div>
                          ))}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}

              {activeStep === 2 && (
                <>
                  <Row>
                    <Col sm={6}>
                      <div
                        className="form-group"
                        style={{ paddingRight: "0.9rem" }}
                      >
                        <label className="form-label" htmlFor="dateOfCeremony">
                          Date of Ceremony
                        </label>
                        <Field
                          type="date"
                          name="dateOfCeremony"
                          className={`form-control placeholder-text ${
                            !values?.dateOfCeremony ? "date-input" : ""
                          }`}
                        />
                        <ErrorMessage
                          name="dateOfCeremony"
                          component="span"
                          className="error text-danger"
                        />
                      </div>
                    </Col>
                    <Col sm={6}>
                      <div
                        className="form-group"
                        style={{ paddingRight: "0.9rem" }}
                      >
                        <label className="form-label">Time of Ceremony </label>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            name="timeOfCeremony"
                            onChange={(newTime) => {
                              const formattedTime = newTime.format("h:mm A");
                              setFieldValue("timeOfCeremony", formattedTime);
                            }}
                            value={
                              values.timeOfCeremony
                                ? dayjs(values.timeOfCeremony, "h:mm A")
                                : null
                            }
                            format="h:mm A"
                            textField={(props) => (
                              <Field
                                {...props}
                                variant="outlined"
                                fullWidth
                                placeholder="Select time"
                                className="time-picker"
                              />
                            )}
                          />
                        </LocalizationProvider>

                        <ErrorMessage
                          name="timeOfCeremony"
                          component="p"
                          className="error text-danger mb-0 pb-0"
                        />

                        <span className="error timeOfCeremonyMessage">
                          e.g., 10:00 AM
                        </span>
                      </div>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col>
                      <div className="form-label">
                        <label className="form-label">
                          Location of Ceremony{" "}
                        </label>
                        <Field
                          type="text"
                          name="locationForCeremony"
                          placeholder="Cypress Lawn Funeral Home, Tiffany Chapel, 1370 El Camino Real, Colma, California "
                          className="form-control placeholder-text custom-field"
                        />
                        <ErrorMessage
                          name="locationForCeremony"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row className="mt-4">
                    <Col sm={6}>
                      <div className="form-label mb-2">
                        <label className="form-label">
                          Officiating Person/Group{" "}
                        </label>
                        <Field
                          type="text"
                          placeholder="Reverend Johnson"
                          className="form-control placeholder-text custom-field"
                          name="officiatingPersonForCeremony"
                        />
                        <ErrorMessage
                          name="officiatingPersonForCeremony"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </Col>
                    <Col sm={6}>
                      <div className="form-group mb-2">
                        <label className="form-label">Contact Person </label>
                        <Field
                          name="contactPersonForCeremony"
                          placeholder="Sarah Smith (555-5678)"
                          type="text"
                          className="form-control placeholder-text custom-field"
                        />
                        <ErrorMessage
                          name="contactPersonForCeremony"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col>
                      <div className=" form-group mb-2">
                        <label className="form-label">
                          Memorial Donations / Flowers
                        </label>
                        <Field
                          name="memorialDonationsForCeremony"
                          placeholder="California State Parks Foundation"
                          className="form-control placeholder-text custom-field"
                          type="text"
                        />
                        <ErrorMessage
                          name="memorialDonationsForCeremony"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <div className=" form-group mb-2">
                        <label className="form-label">Reception Detail</label>
                        <Field
                          name="receptionDetailForCeremony"
                          placeholder="Grand Hotel Ballroom, 6:00 PM"
                          type="text"
                          className="form-control placeholder-text custom-field"
                        />
                        <ErrorMessage
                          name="receptionDetailForCeremony"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </Col>
                  </Row>
                </>
              )}
              {activeStep === 3 && (
                <React.Fragment>
                  <Row className="mt-2">
                    <Col>
                      <div className="form-group">
                        <label className="form-label">
                          Writing Tone <span className="text-danger">*</span>
                        </label>
                        {/* {touched?.email && !values?.writingTone && (
                          <p
                            name="writingTone"
                            component="div"
                            className="error pt-0 mt-0 mb-1 text-danger">
                            Writing tone is required
                          </p>
                        )} */}
                        <div className="writingToneOptions mb-2 p-2">
                          {userTonesOptions.map((userTone) => (
                            <div
                              className="btn btn-sm btn-personality p-2 m-1"
                              key={userTone.value}
                              onClick={() => {
                                setSelectedWritingTone(userTone.label);
                                setWritingToneMessage(userTone.message);
                                setFieldValue("writingTone", userTone.label);
                              }}
                              style={
                                selectedWritingTone === userTone.label
                                  ? {
                                      background: "#F57C00",
                                      color: "white",
                                      fontWeight: "bold",
                                    }
                                  : { backdropFilter: "fff" }
                              }
                            >
                              {userTone.label}
                            </div>
                          ))}
                        </div>
                        <ErrorMessage
                          name="writingTone"
                          component="div"
                          className="error text-danger"
                        />
                        {wrtingToneMessage && (
                          <span className="writing-message">
                            {" "}
                            * {wrtingToneMessage}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col>
                      <div className="form-group mt-1">
                        <label>
                          Length <span className="text-danger"> * </span>
                        </label>
                        {/* {touched?.email && !values?.length && (
                          <p
                            name="length"
                            component="div"
                            className="error pt-0 mt-0 mb-1 text-danger">
                            Length is required
                          </p>
                        )} */}
                        <div className="lenghtOptions mt-1">
                          {lengthOptions.map((length) => (
                            <div
                              className={`btn btn-sm btn-personality p-2 m-1 mb-0`}
                              key={length.value}
                              onClick={() => {
                                setSelectedLength(length.value);
                                setFieldValue("length", length.value);
                              }}
                              style={
                                selectedLength === length.value
                                  ? {
                                      background: "#F57C00",
                                      color: "white",
                                      fontWeight: "bold",
                                    }
                                  : { backdropFilter: "fff" }
                              }
                            >
                              {length.label}
                            </div>
                          ))}
                          <ErrorMessage
                          name="length"
                          component="div"
                          className="error text-danger"
                        />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <div className="ps-1 form-group">
                        <label className="form-label">
                          <Field
                            className="checkbox-input me-2"
                            type="checkbox"
                            name="memorableQuote"
                            checked={values.memorableQuote}
                            onChange={(event) => {
                              setFieldValue(
                                "memorableQuote",
                                event.target.checked
                              );
                            }}
                          />
                          Include a memorable quote
                        </label>

                        <ErrorMessage
                          name="memorableQuote"
                          component="span"
                          className="error text-danger"
                        />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </div>

            {/* about the google recaptcha */}
            {showCaptcha && (
              <React.Fragment>
                <div className="overlay"></div>
                <div
                  className="recaptcha-screen rounded-4"
                  style={{ zIndex: "999" }}
                >
                  {/* <p className="text-center fw-bold">Verify Email Id</p> */}

                  <div className=" pb-2 mt-2 mb-2">
                    <label
                      htmlFor="emailId"
                      className="form-label fw-medium text-center"
                      style={{ width: "280px" }}
                    >
                      Please Enter Your Email ID
                      <span className="text-danger"> *</span>
                    </label>
                    <div className="">
                      <Field
                        type="text"
                        className="form-control custom-email"
                        id="email"
                        name="email"
                        placeholder="Enter Email ID"
                        onChange={(e) => {
                          setErrorMessage("");
                          setFieldValue("email", e.target.value);
                        }}
                      />
                      {errorMessage && (
                        <p className="m-auto email-error pt-1 ps-2 pe-3 text-danger">
                          {errorMessage}
                        </p>
                      )}
                      {touched?.email && !errorMessage && (
                        <ErrorMessage
                          name="email"
                          component="p"
                          className="text-danger m-auto pt-1 ps-2 pe-3 email-error"
                        />
                      )}
                    </div>
                  </div>

                  <label
                    className="form-label fw-medium mt-1 text-center"
                    style={{ width: "260px" }}
                  >
                    Please Provide Captcha{" "}
                    <span className="text-danger fw-medium">*</span>
                  </label>
                  <div
                    className="mb-3"
                    style={{ width: "304px", margin: "auto" }}
                  >
                    <Reaptcha
                      id="recaptcha"
                      sitekey={siteKey}
                      ref={captchaRef}
                      onVerify={handleVerify}
                      onExpire={() => {
                        captchaRef.current.reset();
                        setIsVerified(false);
                      }}
                    />
                  </div>
                  <Row className="mt-1">
                    <Col>
                      <div
                        className="mb-1 form-group m-auto ps-2"
                        style={{ fontSize: "15px", width: "325px" }}
                      >
                        <label className="form-label">
                          <Field
                            className="checkbox-input me-2"
                            type="checkbox"
                            name="termsandcondition"
                          />
                          I accept all the{" "}
                          <a
                            href="https://cypresslawn.com/privacy-policy/"
                            target="blank"
                            style={{ color: "#65a386" }}
                            className="fw-medium"
                          >
                            Terms and Conditions
                            <u>
                              <SVG height="15px" />
                            </u>
                          </a>
                          <span className="text-danger fw-medium">*</span>
                          {"."}
                        </label>

                        <ErrorMessage
                          name="termsandcondition"
                          component="p"
                          className="error text-danger mb-2 ps-0"
                        />
                      </div>
                    </Col>
                  </Row>

                  <div className="btns mt-2">
                    <button
                      className="btn"
                      style={{ backgroundColor: "#C8C8C8", width: "30%" }}
                      onClick={() => {
                        setShowCaptcha(false);
                        setIsVerified(false);
                        setFieldValue("email", "");
                        setFieldValue("termsandcondition", false);
                        setErrorMessage("");
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-success"
                      style={{ width: "30%" }}
                      type="submit"
                      onClick={() => {
                        if (values.email && isVerified) {
                          values = { ...values, draftState: "generate" }
                          handleSubmit(values);
                        }
                      }}
                      disabled={
                        !isVerified || values.termsandcondition === false
                      }
                    >
                      Generate
                    </button>
                  </div>
                </div>
              </React.Fragment>
            )}
            <div className="bottom-container pt-2">
              {activeStep !== 0 && (
                <button
                  disabled={activeStep === 0}
                  className="btn back-button"
                  type="button"
                  onClick={handleBack}
                >
                  Back
                </button>
              )}
              {activeTab !== "Obituary"
                ? dirty
                  ? setFieldsUpdated(true)
                  : setFieldsUpdated(false)
                : ""}
              <Tooltip
                TransitionComponent={Zoom}
                enterDelay={501}
                title={
                  activeTab === "Obituary" && activeStep === 3 && dirty
                    ? isDisplayedInIframe ? !regenerateBtn ? "Generate the obituary with given details" : "Regenerate the obituary with given details" : "Regenerate the obituary with given details"
                    : ""
                }
                arrow
                placement="top"
              >
                <button
                  variant="contained"
                  color="primary"
                  type="submit"
                  // className="btn btn-next btn-success"
                  className={
                    activeStep !== 3
                      ? "btn btn-next btn-success"
                      : "btn btn-generate btn-success"
                  }
                  // disabled={
                  //   activeTab === "Obituary" && activeStep === 3 && !dirty
                  // }
                >
                  {isLastStep && activeTab === "Obituary"
                    ? isDisplayedInIframe ?  !regenerateBtn ? !draftPresence ? "Generate" : "Re-Generate" : "Re-Generate" : "Re-Generate"
                    : "Next"}
                    
                </button>
              </Tooltip>
            </div>
          </Form>
        )}
      </Formik>
    </Box>
  );
}