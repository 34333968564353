import * as Yup from "yup";

export const userTonesOptions = [
  // {
  //   value: 13,
  //   label: "CypressLawn Tone",
  //   message: "Custom tone specifically for Cypress Lawn: Launching Soon",
  // },
  {
    value: 1,
    label: "Respectful",
    message:
      "A respectful tone is formal and honors the life and achievements of the deceased with dignity and reverence.",
  },
  {
    value: 2,
    label: "Loving",
    message:
      "A loving tone expresses deep affection and fondness for the person who passed away, highlighting their impact on the lives of others.",
  },
  {
    value: 3,
    label: "Warm",
    message:
      "A warm tone is inviting and comforting, emphasizing the positive qualities and cherished memories of the deceased.",
  },
  {
    value: 4,
    label: "Appreciative",
    message:
      "An appreciative tone focuses on expressing gratitude for the life and contributions of the deceased to family, friends, and the community.",
  },
  {
    value: 5,
    label: "Nostalgic",
    message:
      " A nostalgic tone evokes memories of the past and recalls the special moments shared with the person who passed away.",
  },
  {
    value: 6,
    label: "Celebatory",
    message:
      "A celebratory tone celebrates the life and accomplishments of the deceased, emphasizing their successes and joys.",
  },
  {
    value: 7,
    label: "Reflective",
    message:
      "A reflective tone encourages readers to contemplate the meaning and significance of the life lived and the legacy left behind.",
  },
  {
    value: 9,
    label: "Hopeful",
    message:
      "A hopeful tone focuses on the lasting impact of the deceased on others and the positive influence they had in their lifetime.",
  },
  {
    value: 10,
    label: "Inspirational",
    message:
      "An inspirational tone shares the deceaseds life journey and how their courage and strength can serve as an inspiration to others.",
  },
  {
    value: 11,
    label: "Sympathetic",
    message:
      "A sympathetic tone acknowledges the grief and loss felt by family and friends and offers condolences and support.",
  },
  {
    value: 12,
    label: "Gentle",
    message:
      "A gentle tone is tender and soothing, providing comfort to those mourning the loss.",
  },
];

export const hobbieOptions = [
  { value: 1, label: "Reading" },
  { value: 2, label: "Writing" },
  { value: 3, label: "Cooking" },
  { value: 4, label: "Painting" },
  { value: 5, label: "Photography" },
  { value: 6, label: "Playing a musical instrument" },
  { value: 7, label: "Drawing" },
  { value: 8, label: "Gardening" },
  { value: 9, label: "Hiking" },
  { value: 10, label: "Cycling" },
  { value: 11, label: "Running" },
  { value: 12, label: "Swimming" },
  { value: 13, label: "Dancing" },
  { value: 14, label: "Birdwatching" },
  { value: 15, label: "Knitting" },
  { value: 16, label: "Sewing" },
  { value: 17, label: "Pottery" },
  { value: 18, label: "Woodworking" },
  { value: 19, label: "Collecting stamps" },
  { value: 20, label: "Collecting coins" },
  { value: 21, label: "Playing video games" },
  { value: 22, label: "Playing board games" },
  { value: 23, label: "Playing chess" },
  { value: 24, label: "Playing card games" },
  { value: 25, label: "Camping" },
  { value: 26, label: "Fishing" },
  { value: 27, label: "Traveling" },
  { value: 28, label: "Playing sports" },
  { value: 29, label: "Yoga" },
  { value: 30, label: "Meditation" },
  { value: 31, label: "Volunteering" },
  { value: 32, label: "Cooking/baking" },
  { value: 33, label: "Home DIY projects" },
  { value: 34, label: "Sculpting" },
  { value: 35, label: "Calligraphy" },
  { value: 36, label: "Baking" },
  { value: 37, label: "Origami" },
  { value: 38, label: "Model building" },
  { value: 39, label: "Astronomy" },
  { value: 40, label: "Surfing" },
  { value: 41, label: "Horseback riding" },
  { value: 42, label: "Wine tasting" },
  { value: 43, label: "Crossword puzzles" },
  { value: 44, label: "Sudoku" },
  { value: 45, label: "DIY crafts" },
  { value: 46, label: "Archery" },
  { value: 47, label: "Bowling" },
  { value: 48, label: "Playing darts" },
  { value: 49, label: "Martial arts" },
  { value: 50, label: "Genealogy research" },
];

export const personalTraitsOptions = [
  { value: 1, label: "Kind" },
  { value: 2, label: "Compassionate" },
  { value: 3, label: "Generous" },
  { value: 4, label: "Empathetic" },
  { value: 5, label: "Honest" },
  { value: 6, label: "Trustworthy" },
  { value: 7, label: "Optimistic" },
  { value: 8, label: "Confident" },
  { value: 9, label: "Creative" },
  { value: 10, label: "Ambitious" },
  { value: 11, label: "Humble" },
  { value: 12, label: "Patient" },
  { value: 13, label: "Open-minded" },
  { value: 14, label: "Loyal" },
  { value: 15, label: "Reliable" },
  { value: 16, label: "Resilient" },
  { value: 17, label: "Adventurous" },
  { value: 18, label: "Charismatic" },
  { value: 19, label: "Curious" },
  { value: 20, label: "Independent" },
  { value: 21, label: "Assertive" },
  { value: 22, label: "Caring" },
  { value: 23, label: "Considerate" },
  { value: 24, label: "Empowered" },
  { value: 25, label: "Enthusiastic" },
  { value: 26, label: "Thoughtful" },
  { value: 27, label: "Persistent" },
  { value: 28, label: "Adaptable" },
  { value: 29, label: "Humorous" },
  { value: 30, label: "Calm" },
];

export const genderOptions = [
  { value: 1, label: "Male" },
  { value: 2, label: "Female" },
];

export const lengthOptions = [
  { value: 700, label: "High" },
  { value: 500, label: "Medium" },
  { value: 350, label: "Low" },
];
const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const validationSchema = [
  Yup.object().shape({
    firstName: Yup.string()
      .matches(/^[a-zA-Z. ]+$/, "Invalid name format")
      .required("* First Name is required"),
    middleName: Yup.string().matches(/^[a-zA-Z. ]+$/, "Invalid name format"),
    lastName: Yup.string().matches(/^[a-zA-Z. ]+$/, "Invalid name format"),
    nickName: Yup.string(),
    birthplace: Yup.string(),
    placeLived: Yup.string(),
    dateOfBirth: Yup.date().max(
      new Date(),
      "Date of birth cannot be in the future"
    ),
    dateOfDeath: Yup.date().max(
      new Date(),
      "Date of death cannot be in the future"
    ),
    deathCause: Yup.string(),
    gender: Yup.string().required("* Select Gender"),
    educationalQualification: Yup.string(),
    familyMembers: Yup.string(),
  }),
  Yup.object().shape({
    // hobbies: Yup.array().nullable(),
    significantLifeMoments: Yup.string().nullable(),
    memories: Yup.string().nullable(),
    achievements: Yup.string().nullable(),
    // personalityTraits: Yup.array().nullable(),
  }),
  Yup.object().shape({
    dateOfCeremony: Yup.date().nullable(),
    timeOfCeremony: Yup.string(),
    locationForCeremony: Yup.string(),
    officiatingPersonForCeremony: Yup.string(),
    contactPersonForCeremony: Yup.string(),
    memorialDonationsForCeremony: Yup.string(),
    receptionDetailForCeremony: Yup.string(),
  }),
  Yup.object().shape({
    writingTone: Yup.string().nullable().required("* Select the Writing Tone"),
    length: Yup.string().required("* Length is required"),
  }),
  Yup.object().shape({
    email: Yup.string()
      .matches(emailRegex, "Enter Valid Email Address")
      .required("Email is required to proceed"),
    termsandcondition: Yup.bool().oneOf(
      [true],
      "* Please accept the Terms and Conditions"
    ),
  }),
];

// intial values
export const initialValues = {
  email: "",
  firstName: "",
  middleName: "",
  lastName: "",
  nickName: "",
  dateOfBirth: "",
  dateOfDeath: "",
  birthplace: "",
  placeLived: "",
  deathCause: "",
  familyMembers: "",
  personalityTraits: "",
  hobbies: "",
  gender: "",
  significantLifeMoments: "",
  writingTone: "",
  achievements: "",
  educationalQualification: "",
  memories: "",
  length: "",
  termsandcondition: false,
  dateOfCeremony: "",
  timeOfCeremony: "",
  locationForCeremony: "",
  officiatingPersonForCeremony: "",
  contactPersonForCeremony: "",
  memorialDonationsForCeremony: "",
  receptionDetailForCeremony: "",
  memorableQuote: false,
  applicationKey: "ObituaryWriterWeb",
  draftState:""
};

export const steps = [
  "Basic Information",
  "Personality Details",
  "Ceremony details",
  "Obituary Settings",
];
