import React, { useEffect, useState } from "react";
import "./ObituaryContainer.css";
import CopyToClipboard from "react-copy-to-clipboard";
import PopupContainer from "../popup/PopupContainer";

function ObituaryContainer(props) {
  let { gptData, setSubmitDraft, draftPresence, setGeneratedData, generatedData,
    setDraftPresence, showAllButtons, setShowAllButtons } = props;
  const [copiedText, setCopiedText] = useState("");
  const [isCopied, setIsCopied] = useState(false);


  // Use state to manage the textarea content for editing
  let [generatedText, setGeneratedText] = useState()
  const [textareaValue, setTextareaValue] = useState(gptData?.data);
  const [showSaveDraftsPopup, setSaveDraftsPopup] = useState(false)
  const [showPostObituaryPopup, setPostEditorPopup] = useState(false)
  

  // Use state to manage the content to be copied
  const [copyContent, setCopyContent] = useState(gptData?.data);

  // Function to handle changes in the textarea
  const handleTextareaChange = (event) => {
    setTextareaValue(event.target.value);
    setGeneratedText(event.target.value);
    setGeneratedData(event.target.value);
  };

  useEffect(() => {
    setTextareaValue(gptData?.data);
  }, [gptData?.data]);

  useEffect(()=>{
    if(generatedData?.data){
      setGeneratedText(generatedData?.data)
    }
  }, [generatedData])
  
  // Function to handling copy
  const handleCopy = () => {
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2100);
    setCopiedText(copyContent);
  };

  const handleCloseButtonClick = ()=>{
    if(window.parent){
      window.parent.postMessage("close", "*")
      setShowAllButtons(false)
    }
  }

  const formatTextareaValue = (value)=>{
    let formattedValue = value?.replace(/\n\n/g, '</p><p>');
    formattedValue = formattedValue?.replace(/\n/g, '<br>');
    formattedValue = '<p>' + formattedValue + '</p>';
    return formattedValue;
  }
  
  const handleSendToEditor = ()=>{
    if(window.parent){
      const formattedTextareaValue = formatTextareaValue(draftPresence ? generatedText : textareaValue)
      window.parent.postMessage({action : 'post-data', formattedTextareaValue}, "*")
      setSubmitDraft(true)
    }
  }

  useEffect(() => {
    window.addEventListener("message", (e) => {
      if(e?.data?.generatedText){
        setGeneratedText(e?.data?.generatedText)
        setDraftPresence(true)
        setShowAllButtons(true)
      }
    });
  }, []);
  

  const isDisplayedInIframe = window.self !== window.top;

  return (
    
    <div
      className={isDisplayedInIframe ? showAllButtons ? "obituary-container-iframe" :  "obituary-container" :  "obituary-container"}
      style={{
        width: "80%",
        margin: "auto",
        marginTop: "1.5rem",
      }}
    >
       {showSaveDraftsPopup &&
      <PopupContainer
       message="Are you sure you want to save this draft ?"
        additionalMessage="Saving this will replace any existing draft."
        onMainButtonClick={()=>{setSubmitDraft(true); handleCloseButtonClick()}}
        onCloseButtonClick={()=> setSaveDraftsPopup(false)}
        mainButton="Save & Close"
        secondaryButton="Cancel"
      />
      }
      {showPostObituaryPopup &&
          <PopupContainer
          message="Posting this obituary will overwrite any existing text in the editor."
          additionalMessage="Are you sure you want to continue ?"
          onMainButtonClick={handleSendToEditor}
          onCloseButtonClick={()=> setPostEditorPopup(false)}
          mainButton="Post & Close"
          secondaryButton="Cancel"
        />
      }
      <p
        style={{ fontSize: "1.5rem", fontWeight: "600" }}
        className="pt-2 mb-0 text-center"
      >
        Obituary Draft{" "}
      </p>

      <div className="d-flex m-2 mb-0 ">
        <textarea
          style={{
            height: isDisplayedInIframe ? showAllButtons? "67vh" : "71vh" : "67vh",
            border: "1px solid var(--Stoke_color, rgba(146, 160, 179, 0.60))",
            borderRadius: "2%",
            width: "80%",
            margin: "auto",
            padding: "2%",
            fontSize: "15px",
          }}
          value={draftPresence ? generatedText : textareaValue}
          onChange={handleTextareaChange}
        />
      </div>
      <p
        style={{
          fontSize: "12px",
          color: "#65a386",
          width: "77%",
          margin: "auto",
          paddingTop: "1%",
        }}
      >
        <span className="text-danger fw-medium">*</span> This Obituary draft is
        generated using Artificial Intelligence and may contain randomness.
        Please evaluate before using the Obituary draft.
      </p>
      <div className="bottom-buttons p-3 pt-2 pb-1">
      {isDisplayedInIframe && showAllButtons &&
          <>
            <button className="btn other-buttons" onClick={()=>setPostEditorPopup(true)}>Post to editor</button>
            <button className="btn other-buttons" onClick={()=>setSaveDraftsPopup(true)}>Save as draft</button>
            <CopyToClipboard
              text={draftPresence ? generatedText : textareaValue}
              onCopy={() => handleCopy(draftPresence ? generatedText : textareaValue)}
            >
              <button className="btn btn-success copy-button">
                {isCopied ? <>Copied ✓</> : <>Copy Obituary Text</>}
              </button>
            </CopyToClipboard>
          </> 
        }
        {!isDisplayedInIframe &&  
          <CopyToClipboard
              text={draftPresence ? generatedText : textareaValue}
              onCopy={() => handleCopy(draftPresence ? generatedText : textareaValue)}
            >
              <button className="btn btn-success copy-button">
                {isCopied ? <>Copied ✓</> : <>Copy Obituary Text</>}
              </button>
          </CopyToClipboard>
        }
      </div>
    </div>
  );
}

export default ObituaryContainer;