import React from 'react';
import warning from "../../assets/warning.png"


const PopupContainer = ({message, additionalMessage, onCloseButtonClick, onMainButtonClick, mainButton, secondaryButton }) => {
  return (
    <div className="overlay">
      <div className="close-popup-container text-center rounded-4">
        <div className="popup-content">
          <img src={warning} alt="warning logo" width={100} height={100} />
          <p className="popup-text">
            {message}
          </p>
          {additionalMessage && 
            <p style={{fontSize: "16px"}} className="pt-2 popup-text">
              {additionalMessage}
            </p>
          }
        </div>
        <div className="m-auto mt-3">
          <button
            className="btn okay-button fw-medium me-4"
            style={{ margin: "1%" }}
            onClick={onCloseButtonClick}
          >
            {secondaryButton}
          </button>
          <button
            className="btn close-button fw-medium ms-4"
            onClick={onMainButtonClick}
          >
            {mainButton}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopupContainer;
