import { configureStore } from "@reduxjs/toolkit";
import FormReduer from "./reducers/FormReduer";
import NavReducer from "./reducers/NavReducer";

const Store = configureStore({
  reducer: {
    form: FormReduer,
    nav: NavReducer,
  },
});

export default Store;
