import React, { useState } from "react";
import "./Header.css";
import ObituaryGpt from "../../assets/ObituaryWriter.png";
import CypressLogo from "../../assets/CypressLogo.png"
import { useSelector } from "react-redux";
import { RiCloseLine } from "react-icons/ri";
import warning from "../../assets/warning.png"

function Header({ isFieldsUpdated, regenerateBtn, setShowAllButtons, showAllButtons }) {
  const isDisplayedInIframe = window.self !== window.top;
  const navSelected = useSelector((state) => state.nav.selectedNavItem);
  const selectedNav = isDisplayedInIframe ? !regenerateBtn ? !showAllButtons ? "Biographical Details" : navSelected : navSelected : navSelected

  const textBio =
    selectedNav === "Biographical Details"
      ? "text-bio"
      : "not-selected-biography";
  const textOrbitary =
    selectedNav.activeTab === "Obituary"
      ? "text-orbitary"
      : "not-selected-orbitary";

  const [popupVisible, setPopupVisible] = useState(false);
  const [closeButtonPopup, setCloseButtonPopup] = useState(false)

  const handleRefresh = () => {
    setPopupVisible(false);
    if(!isDisplayedInIframe){ window.location.href = window.location.href}
  };

  // Returning the component
  const handleNavigateToWeb = () =>{
    !isDisplayedInIframe &&
    window.open('https://cypresslawn.com/obituary-overview/', '_blank');
  }

  const handleCloseButtonClick = ()=>{
    if(window.parent){
      window.parent.postMessage("close", "*")
      setShowAllButtons(false)
    }
  }

  return (
    <>
      <div className={isDisplayedInIframe ? "header-iframe" : "header"}>
        <div className="image-logo">
       {!isDisplayedInIframe &&
        <img
            src={CypressLogo}
            alt="Cypress Lawn Logo"
            // className="Cypress-logo mt-1"
            className={isDisplayedInIframe ? "Cypress-logo-iframe mt-1": "Cypress-logo mt-1"}
            onClick={handleNavigateToWeb}
          />
       }
          <img
            src={ObituaryGpt}
            alt="ObituaryGPT"
            // className="writergpt-logo mt-2"
            className={isDisplayedInIframe ? "writergpt-logo-iframe mt-1" : "writergpt-logo mt-2"}
            onClick={
              isFieldsUpdated
                ? () => setPopupVisible(true)
                : () => handleRefresh()
            }
          />
        </div>
        {/* Details */}
        <div className="header-text">
          <div
            className={`${
              selectedNav === "Biographical Details"
                ? "biographical"
                : "biographical-section"
            }`}
          >
            <span className={`${textBio}`}>Biographical Details</span>
          </div>
          <div
            className={`${
              selectedNav.activeTab === "Obituary"
                ? "orbitary"
                : "orbitary-section"
            }`}
          >
            <span className={`${textOrbitary}`}>Obituary</span>
          </div>
          {isDisplayedInIframe &&
              <button className="btn btn-white cross-button" onClick={()=>setCloseButtonPopup(true)}><RiCloseLine size={28} /></button>
          }
        </div>
        
        {closeButtonPopup &&
        <>
          <div className="overlay"> </div>
            <div className="close-popup-container text-center rounded-4">
              <div className="popup-content">
                <img src={warning} alt="warning logo" width={100} height={100} />
                <p className="popup-text">
                Are you sure you want to close the Obituary Writer? 
              </p>
                <p className="popup-text">To continue closing, please click “Okay.” <br/> </p>
                <p className="popup-text">If not, please click “Cancel.” </p>
              </div>
              <div className="m-auto mt-1">
                <button
                  className="btn close-button fw-medium me-4"
                  onClick={() => setCloseButtonPopup(false)}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-success okay-button fw-medium ms-4"
                  style={{ margin: "1%" }}
                  onClick={handleCloseButtonClick}
                >
                  Okay
                </button>
              </div>
            </div>
            </>
          }

        {popupVisible && (
          <>
            <div className="overlay"> </div>
            <div className="popup-container text-center rounded-4">
              <div className="popup-content">
                <p className="popup-text">
                  Refreshing will clear your current data and reload the page.
                </p>
                <p className="popup-text pb-2 pt-3">
                  Are you sure you want to continue?
                </p>
              </div>
              <div className="m-auto">
                <button
                  className="btn close-button fw-medium me-2"
                  onClick={() => setPopupVisible(false)}
                >
                  Close
                </button>
                <button
                  className="btn btn-success fw-medium ms-2"
                  style={{ margin: "1%" }}
                  onClick={handleRefresh}
                >
                  Proceed
                </button>
              </div>
            </div>
          </>
        )}
        {/* {loading === true && (
          <>
            <Loader />
          </>
        )} */}
      </div>
    </>
  );
}

export default Header;