import React from "react";
import "./loader.css";

const Loader = () => {
  return (
    <>
      {" "}
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          top: 0,
          left: 0,
          right: 0,
          width: "100vw",
          bottom: 0,
          height: "100vh",
          zIndex: 2000,
          position: "fixed",
          backgroundColor: "rgb(0 0 0 / 50%)",
        }}
      >
        <div className="loader-wrapper">
          <span className="loader"></span>
          <p className="loader-title">
            Just a moment, we're working on obituary draft !
          </p>
        </div>
      </div>
    </>
  );
};

export default Loader;
